// This file contains styling for the table of contents
// section which appears on the right side of the page.

// Also, the design for smaller screens with width 76px or less
// is defined here under the mobile class

.toc-wrapper {
  @media screen and (min-width: 1441px) {
    padding-left: $spacer * 1.25;
  }

  h5 {
    margin-bottom: $spacer * 1.25;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    .toc-container {
      position: -webkit-sticky;
      position: sticky;
      top: 120px;
      padding-left: $spacer * 1.5;
      border-left: 1px solid $border-color;
    }
  }

  // Styling applied when view is in mobile mode
  &.mobile {
    .toc-container {
      padding: $spacer * 0.75 $spacer;
      position: relative;
      background-color: $white-alter;
      box-shadow: $shadow-tab;
      margin-bottom: $spacer * 2;
      z-index: 1;
    }

    h5 {
      margin-bottom: 0;
    }

    .hevo-dropdown-arrow {
      display: inline-block;
      cursor: pointer;
      transition: $transition-base;
      &.up {
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .section-nav {
      position: absolute;
      left: 0px;
      right: 0px;
      top: calc(100% - 1px);
      padding: $spacer * 1.5 $spacer;
      background-color: $white-alter;
      box-shadow: $shadow-tab;
    }
  }
}

.section-nav {
  display: block;
  max-height: 75vh;
  overflow-y: auto;

  .nav-link {
    font-size: $font-size-xs;
    padding: 0;
    color: $text-secondary-color;
    @include hover-focus {
      color: $primary;
    }
  }

  .nav-link.active {
    color: $primary;
  }

  .nav-item + .nav-item {
    margin-top: $spacer;
  }

  .nav-link + .subsection-nav {
    margin-top: $spacer * .5;
  }

  // Styling for subsection in ToC
  .subsection-nav {
    display: block;
    padding-left: $spacer * 0.75;

    .nav-item + .nav-item {
      margin-top: $spacer * 0.5;
    }
  }
}
