/* 
  mixin to style the containers of different note types
 */
@mixin note-container-styling($container-type-color) {
  $icon-size: $font-size-base * 2.5;
  $container-padding: $spacer * 1.25;
  $icon-right-margin: $spacer * .75;
  padding: $spacer * 1.25;
  margin-bottom: $spacer * 2.5;
  display: flex;
  align-items: flex-start;
  border: 1px solid $container-type-color;
  border-radius: $border-radius-lg;
  position: relative;
  padding-left: $container-padding + $icon-size + $icon-right-margin;

  .hevo-docs-icon {
    position: absolute;
    top: $container-padding;
    left: $container-padding;
    color: $container-type-color;
    font-size: $icon-size;
  }

  .note-wrapper {
    flex: 1;
    min-width: 0;
  }

  p {
    margin: 0 !important;
  }

  * + & {
    margin-top: $spacer * 2.5;
  }
}

/*
 * Widgets to highlight info and warning notes in the flow of document
 */

.info-note-container {
  @include note-container-styling($note-container-color-info);
}

.warning-note-container {
  @include note-container-styling($note-container-color-warn);
}

.error-note-container {
  @include note-container-styling($note-container-color-error);
}
