#search_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

#search_input {
  position: relative;
  z-index: 3;
  padding-right: 50px;
}

.loading-indicator {
  width: 24px;
  height: 24px;
  display: block;
  right: 30px;
  position: absolute;
  z-index: 9;
  border: 2px solid $off-white;
  border-radius: 50%;
  border-top-color: $text-secondary-color;
  -webkit-animation: spin .5s linear infinite;
  animation: spin .5s linear infinite;
}

.clear-search {
  position: relative;
  z-index: 4;
}

#hits {
  position: relative;
}

#hits .list-group {
  position: absolute;
  z-index: 5;
  right: -$spacer;
  left: -$spacer;
  top: 100%;
  box-shadow: $shadow-search-bar;
  border: 1px solid $border-color;
  border-radius: $border-radius-search;
  max-height: 500px;
  overflow: auto;

  .hits-filter-caption {
    color: $text-secondary-color;
    font-size: $font-size-sm;
    background-color: $white;
    padding-top: $spacer * .5;
    padding-left: $spacer * .75;
  }

  .list-group-item {
    padding: $spacer * .5 $spacer * 1.25;
    margin-bottom: 0;
    border: 0;
  }

  .list-group-item + .list-group-item {
    border-top: solid 1px $border-color;
  }

  .list-group-item-active {
    background-color: $primary-bg-faded-1-color;
  }

  strong.text-danger {
    color: $primary !important;
    font-weight: $font-weight-semi-bold;
  }

  .list-group-item-action {
    color: $text-color;
    font-size: 0.75 * $font-size-base;
  }

  .breadcrumbs {
    font-size: $font-size-base * 0.625;
    color: $text-secondary-color;
    font-weight: $font-weight-normal;
  }

  .group-heading {
    border-bottom: 1px solid $border-color;
    padding-bottom: $spacer * 0.75;
    background: $white;

    .heading-text {
      display: block;
      padding: $spacer * 1.25 $spacer * 1.25 $spacer * .75 ;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      color: #0e2843;
    }
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//TODO: add search list styles

