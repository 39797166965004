.article-feedback-title {
  font-weight: $font-weight-semi-bold;
}

.article-feedback-btn {
  padding: $spacer * 0.25 $spacer;
  @include hover-focus {
    color: $white;
  }

  &:active, &.feedback-given,
  :not(:disabled):not(.disabled):active {
    outline: none;
    background-color: $primary;
    color: $white;
  }
}

.article-feedback-btn + .article-feedback-btn {
  margin-left: $spacer;
}

@include copy-email-styling;
