$top-gutter: 120px;

.sticky-section-wrapper {
  position: sticky;
  background-color: white;
  top: $top-gutter;
  z-index: 1;

  hr {
    padding-bottom: 2.5rem;
  }

  &.sticky {
    top: 0;
    padding-top: $top-gutter;
    margin-top: -$top-gutter;
  }
}
