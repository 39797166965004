// This file contains styling concerned with the index section
// which appears in sources or destinations.
// The index section forms the main content here


.doc-section ul.section-contents:not(:first-child) {
  margin-top: $spacer !important;
}

ul.section-contents {
  padding-left: 0 !important;

  li {
    padding-left: 0 !important;
    list-style: none;
  }

  > li {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-lg;
  }

  a {
    color: $text-strong-color;
  }

  .sub-section-contents.with-bullets {
    padding-top: $spacer * .5;
  }
}

.sub-section-contents {
  list-style-type: lower-alpha;
  padding: $spacer 0 $spacer * .5 0;
  position: relative;
  margin-bottom: 0 !important;
  font-weight: normal;
  font-size: $font-size-md;
  margin-top: 0 !important;

  li + li {
    margin-top: $spacer * .5;
  }

  i.hevo-dropdown-arrow.collapsed {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  i.hevo-dropdown-arrow {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  a.item-has-child {
    padding-right: $spacer * 2.5;
  }

  a {
    font-weight: normal !important;
  }
}

.doc-section ul.sub-section-contents {
  padding-left: 0;
}

.sub-section-contents.collapsed-child {
  margin-left: $spacer * 2;
  padding-top: $spacer * 1.25;
}

.sub-section-contents.collapsed-child.show, .sub-section-contents.collapsed-child:not(.collapse) {
  height: auto !important;
}

.sub-section-contents {
  li::before {
    content: " ";
    width: 6px;
    height: 6px;
    border-radius: 2px;
    background-color: $primary;
    display: inline-block;
    margin-right: $spacer * 0.5;
    margin-bottom: 3px;
  }

  &.no-bullets, &.with-bullets {
    > li > a {
      font-weight: $font-weight-semi-bold !important;
    }
  }

  &.no-bullets > li::before {
    display: none;
  }
}
