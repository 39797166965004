.doc-section, .glossary-term-tooltip .tooltip-inner {
  font-size: $font-size-md;

  p {
    margin-bottom: $spacer * 1;
  }

  a {
    font-weight: $font-weight-semi-bold;
  }

  ol, ul {
    padding-left: $spacer * 1.25;
    margin-bottom: $spacer * 2;
  }

  li + li {
    margin-top: $spacer;
  }

  code.highlighter-rouge {
    display: inline-block;
    background-color: $code-highlighter-bg-color;
    color: $code-highlighter-color;
    font-size: $spacer * 0.875;
    overflow-wrap: break-word;
    word-break: normal;
    padding: 0 4px;
    border-radius: 4px;
    margin: 0 $spacer * .125;
  }

  h2, h3, h4 {
    $appbar-offset: 100px;
    font-weight: $font-weight-semi-bold;
    margin-bottom: $spacer * 1.5;
    position: relative;
    pointer-events: none;
    
    &::before {
      content: "";
      display: block;
      visibility: hidden;
      margin-top: -$appbar-offset;
      padding-top: $appbar-offset;
      pointer-events: none;
    }
  }

  div.highlight {
    position: relative;
  }

  pre.highlight {
    background-color: $off-white;
    padding: $spacer * 1.25 $spacer * 1.75;
    margin-bottom: $spacer * 1.5;
  }

  .btn-clipboard {
    position: absolute;
    right: 15px;
    top: 15px;
    border: 0;
    background-color: transparent;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    font-size: 1.4rem;
    padding: 0;
    transition: $transition-base;
    opacity: 0;
    color: $text-secondary-color;
    z-index: 9;
    outline: none;
  }

  .btn-clipboard.visible {
    opacity: 1;
    outline: none;
  }

  .btn-clipboard:focus {
    outline: none;
  }

  table {
    display: block;
    overflow: auto;
    border: 1px solid $border-color;
    width: 100%;
    width: max-content;
    max-width: 100%;

    code.highlighter-rouge {
      word-break: break-all;
    }
  }

  table th, table td {
    padding: 0.75 * $spacer;
    overflow-wrap: break-word;
  }

  table th {
    border-bottom: 1px solid $border-color;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-sm;
    color: $text-strong-color;
  }

  table tr:nth-of-type(even) {
    background-color: $tr-alternate-bg-color;
  }

  img {
    max-width: 100%;
    @include media-breakpoint-up(xl) {
      max-width: 600px;
    }
  }

  *+p, *+table, *+pre.highlight {
    margin-top: $spacer * 1.5;
  }

  *+h2, *+h3, *+h4 {
    margin-top: $spacer * 2.5;
  }

  *+ul, *+ol {
    margin-top: $spacer;
  }

  li > ul, li > ol {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  li img {
    margin-top: $spacer * 1.5;
    margin-bottom: $spacer * 1.5;
  }

  li img.text-around-icon {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.doc-section {
  @include media-breakpoint-up(xl) {
    padding-right: $spacer * 1.5;
  }
}

// This class is applied on the Last Modifed span
.last-modified {
  color: $text-secondary-color;
  margin-top: $spacer * .25;
  font-size: $font-size-sm;
}

// This class is attached to imgs
.lightgallery-link {
  display: inline-block;
  cursor: zoom-in;
}

.lg-toolbar .lg-icon {
  color: $white;

  &:hover {
    color: $primary;
  }
}

#lg-zoom-out,
#lg-zoom-in,
#lg-actual-size {
  opacity: 1 !important;
}

// Styling for collapsible section
.collapsible-section {
  @include collapsible-content();
}
