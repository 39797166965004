.error-page {
  padding-top: $spacer * 5;
  padding-bottom: $spacer * 9;
}

.error-title {
  font-size: $font-size-base * 2.5;
  font-weight: $font-weight-semi-bold;
  margin-bottom: $spacer * .75;
}

.error-image {
  max-width: 80%;
  margin-top: $spacer * 2.75;
}
