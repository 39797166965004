.btn {
  line-height: 1.4;
  padding: .56 * $spacer .875 * $spacer;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  &-primary {
    border: 0;
    border-radius: $border-radius-xl;
    font-weight: $font-weight-medium;
    color: $white;
    background: $button-bg;
    &:hover,:focus {
      background: $button-bg-hover;
    }
  }
}

.btn-faded-primary {
  background: rgba($primary, .1);
  color: $primary;
  box-shadow: none;
  border-color: transparent;
  text-transform: none;
  font-size: $spacer * 0.95;
  border-radius: $border-radius-xl;

  @include hover-focus() {
    color: $white;
    background: $button-bg-hover;
  }
}
