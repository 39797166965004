body {
  --text-color:                       rgba(43, 48, 59, 1);
  --text-strong-color:                rgba(14, 18, 27, 1);
  --text-secondary-color:             rgba(82, 88, 102, 1);
  --link-color:                       rgba(76, 37, 167, 1);
  --link-hover-color:                 rgba(105, 62, 224, 1);
  --border-color:                     rgba(225, 228, 234, 1);

  --primary-bg-faded-color:           rgba(248, 246, 255, 1);
  --primary-bg-faded-1-color:         rgba(245, 247, 250, 1);
  
  --tr-alternate-bg-color:            var(--primary-bg-faded-1-color);
  --doc-page-title-bg-color:          var(--primary-bg-faded-color);


  --ul-list-border-color:             #{map-get($black-palette, 900)};

  --code-highlighter-color:           #{map-get($red-palette, 800)};
  --code-highlighter-bg-color:        #{map-get($white-palette, 100)};

  --note-container-color-info:        #{map-get($blue-palette, 500)};
  --note-container-color-warn:        #{map-get($yellow-palette, 300)};
  --note-container-color-error:       #{map-get($red-palette, 300)};

  --button-bg:                        linear-gradient(180deg,rgba(255,255,255,0.16) 0,rgba(255,255,255,0) 100%), #4c25a7;
  --button-bg-hover:                  linear-gradient(180deg,rgba(255,255,255,0.24) 0,rgba(255,255,255,0) 100%), rgb(91, 44, 201);
}
