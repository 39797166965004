// This file contains styling related to the header tags
// like h1, h2, h3, etc.


// New design for page title with logo
.doc-title-logo-wrapper {
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  padding: $spacer * 2.5 0;
  margin-bottom: $spacer * 1.25;
  justify-content: space-between;

  .doc-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: $spacer * 2.5;

    .doc-title {
      font-weight: $font-weight-semi-bold;
      font-size: $h1-font-size;
      margin-bottom: $spacer * 1.25;
      line-height: 1.5;
    }
  }

  &.logo-wrapper-sm {
    padding: $spacer * 2 0;
  }
}


.setup-guide-title {
  font-weight: $font-weight-bold;
  font-size: $h1-font-size;
  line-height: 2;
  margin: 12px 0;
}

.doc-page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 $spacer * -1 $spacer * 2.5;
  padding: $spacer;
  background-color: $doc-page-title-bg-color;

  @include media-breakpoint-up(lg) {
    margin: 0 $spacer * -2.5 $spacer * 2.5;
    padding: $spacer $spacer * 2.5;
  }
}

// The following style is applied on the h1 tag 
// which forms the page title of the doc
.doc-page-title {
  font-weight: $font-weight-bold;
  margin: 0;
  padding: 0;
  font-size: $h1-font-size;
  color: $text-strong-color;
}

// The following style is applied on the h2/h3/h4 tags
// which acts as section header

.header-anchor-container {
  display: flex;
  align-items: center;
  pointer-events: initial;
}

.header-anchor-text {
  display: flex;
  align-items: center;

  &:hover .header-anchor {
    visibility: visible;
  }
}

.collapsible-section {
  .header-anchor-text {
    padding-right: $spacer * 1.5;
  }
}

// This class is for the header anchor icon
// which allows copying the link to header
.header-anchor {
  margin-left: $spacer * .5;
  display: inline-flex;
  visibility: hidden;
  font-size: $font-size-base * 1.5;
  color: $primary;
  border: none;
  background-color: transparent;
  &:hover {
    text-decoration: none;
  }
}

// Design for the Step part in H2 header
.step {
  .header-step {
    @include badge-style();
    margin-right: $spacer * .25;
    border-radius: 10rem;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer * .75;
    }
  }
}
