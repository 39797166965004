.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $feedback__container-padding;
  position: fixed;
  bottom: $feedback__container-gutter-y;
  right: $feedback__container-gutter-x;
  border-radius: $border-radius-sm;
  background-color: $surface-bg-color;
  box-shadow: $shadow-elevated;
  z-index: 1050;
  overflow: scroll;
  max-height: 60vh;

  .feedback-close {
    position: absolute;
    top: $feedback__close-position;
    right: $feedback__close-position;
    padding: 0;
    border: 0;
    background: transparent;
    line-height: 1;
    color: #5F697E;

    .hevo-docs-icon {
      font-size: $feedback__close-font-size;
    }
  }

  .feedback-header {
    font-size: $feedback__header-font-size;
    line-height: $feedback__header-line-height;
    margin-bottom: $feedback__header-bottom-spacer;
    font-weight: $font-weight-bold;
  }

  .feedback-info {
    display: flex;
    align-items: center;
    margin-bottom: $spacer;

    .feedback-title {
      margin-left: $feedback__title-left-spacer;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: $feedback__title-font-weight;
    }
  }

  .feedback-submit {
    color: $feedback__submit-btn-color;
    background-color: $feedback__submit-btn-bg-color;
    border-color: transparent;
    margin-top: $feedback__submit-btn-top-spacer;
  }
}
