.glossary-term {
  cursor: help;
  border-bottom: dashed 1px rgba(120, 133, 145, .5);
  white-space: nowrap;
}

.glossary-term-tooltip {
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    background: $white;
    text-align: initial;
    max-width: 290px;
    padding: $spacer * .625;
    border-radius: $border-radius-lg;
    border: solid 1px $border-color;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15);
    font-size: $font-size-xxs;
    color: $text-strong-color;
    max-height: 145px;
    overflow: hidden;
    position: relative;

    &.expanded {
      max-width: 360px;
      max-height: 320px;
      overflow: auto;

      @include media-breakpoint-down(xs) {
        max-width: 280px;
      }
    }

    .see-more-container {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $spacer * .625;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $white 40%);
      width: 100%;
    }

    .see-more {
      cursor: pointer;
      font-weight: $font-weight-semi-bold;
      color: $primary;
    }

    p {
      margin-bottom: 0;
    }

    * + p {
      margin-top: $spacer * .75;
    }

    ul {
      margin-bottom: 0;
      padding-left: $spacer * .75;
      margin-left: .75 * $spacer;
    }

    * + ul {
      margin-top: $spacer;
    }

    ul > li {
      padding-left: 0;
      list-style: initial;
      &::before {
        display: none;
      }
    }

    * + .highlighter-rouge {
      margin-top: $spacer * .75;
    }

    .highlighter-rouge {
      margin-bottom: 0;
    }

    pre.highlight {
      margin: 0;
      padding: $spacer *.5;
    }
  }

  $custom-tooltip-arrow-height: .5rem;
  $custom-tooltip-arrow-width: 1.5rem;
  $custom-tooltip-arrow-color: $white;
  $custom-tooltip-arrow-border: $border-color;
  $custom-tooltip-arrow-offset: -1px;
  $custom-tooltip-arrow-border-offset: 0px;

  .arrow {
    z-index: 1;
    width: $custom-tooltip-arrow-width;
    height: $custom-tooltip-arrow-height;

    &::after {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
      z-index: -1;
    }
  }


  &.bs-tooltip-top {
    padding: $custom-tooltip-arrow-height 0;

    .arrow {
      bottom: 0;

      &::before {
        top: $custom-tooltip-arrow-offset;
        border-width: $custom-tooltip-arrow-height ($custom-tooltip-arrow-width * .5) 0;
        border-top-color: $custom-tooltip-arrow-color;
      }

      &::after {
        top: $custom-tooltip-arrow-border-offset;
        border-width: $custom-tooltip-arrow-height ($custom-tooltip-arrow-width * .5) 0;
        border-top-color: $custom-tooltip-arrow-border;
      }
    }
  }

  &.bs-tooltip-right {
    padding: 0 $custom-tooltip-arrow-height;

    .arrow {
      left: 0;
      width: $custom-tooltip-arrow-height;
      height: $custom-tooltip-arrow-width;

      &::before {
        right: $custom-tooltip-arrow-offset;
        border-width: ($custom-tooltip-arrow-width * .5) $custom-tooltip-arrow-height ($custom-tooltip-arrow-width * .5) 0;
        border-right-color: $custom-tooltip-arrow-color;
      }

      &::after {
        right: $custom-tooltip-arrow-border-offset;
        border-width: ($custom-tooltip-arrow-width * .5) $custom-tooltip-arrow-height ($custom-tooltip-arrow-width * .5) 0;
        border-right-color: $custom-tooltip-arrow-border;
      }
    }
  }

  &.bs-tooltip-bottom {
    padding: $custom-tooltip-arrow-height 0;

    .arrow {
      top: 0;

      &::before {
        bottom: $custom-tooltip-arrow-offset;
        border-width: 0 ($custom-tooltip-arrow-width * .5) $custom-tooltip-arrow-height;
        border-bottom-color: $custom-tooltip-arrow-color;
      }

      &::after {
        bottom: $custom-tooltip-arrow-border-offset;
        border-width: 0 ($custom-tooltip-arrow-width * .5) $custom-tooltip-arrow-height;
        border-bottom-color: $custom-tooltip-arrow-border;
      }
    }
  }

  &.bs-tooltip-left {
    padding: 0 $custom-tooltip-arrow-height;

    .arrow {
      right: 0;
      width: $custom-tooltip-arrow-height;
      height: $custom-tooltip-arrow-width;

      &::before {
        left: $custom-tooltip-arrow-offset;
        border-width: ($custom-tooltip-arrow-width * .5) 0 ($custom-tooltip-arrow-width * .5) $custom-tooltip-arrow-height;
        border-left-color: $custom-tooltip-arrow-color;
      }

      &::after {
        left: $custom-tooltip-arrow-border-offset;
        border-width: ($custom-tooltip-arrow-width * .5) 0 ($custom-tooltip-arrow-width * .5) $custom-tooltip-arrow-height;
        border-left-color: $custom-tooltip-arrow-border;
      }
    }
  }
}

