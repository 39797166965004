.feedback-popup-anchor {
  position: fixed;
  bottom: $feedback-anchor__gutter-y;
  right: $feedback-anchor__gutter-x;
  width: $feedback-anchor__size;
  height: $feedback-anchor__size;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;

  .hevo-docs-icon {
    font-size: $feedback-anchor__icon-font-size;
  }
}
