.sidebar-wrapper {
  padding: $spacer * 1.5 $spacer;
  width: 100%;
}

.sidebar-footer {
  padding-top: $spacer * 1.25;
  margin-top: $spacer;
  border-top: 1px solid $border-color;
}

.sidebar-footer--item {
  margin-bottom: $spacer * 1.25;

  .sidebar-footer--item-link {
    font-weight: $font-weight-semi-bold;
    color: $navbar-light-color;
    text-decoration: none;
    display: flex;
    align-items: center;

    .hevo-docs-icon {
      margin-right: $spacer * .5;
      font-size: 1.25 * $font-size-base;
    }

    @include hover-focus {
      color: $navbar-light-hover-color;
    }
  }
}

.sidebar-menu-toggle {
  transform: rotate(-90deg);
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: $font-size-xs;

  li.active > a .sidebar-menu-toggle {
    transform: rotate(0deg)
  }

  a {
    position: relative;
  }
}

.sidebar-menu-toggle {
  font-size: $font-size-xxs;
  transition: $transition-base;
  position: absolute;
  right: 0;
  width: 40px;
  bottom: 0;
  top: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $text-secondary-color;
}

.sidebar-category-menu > li {
  margin-bottom: $spacer * .75;
  &.active {
    .sidebar-category-item {
      background: $primary-bg-faded-1-color;
    }
    .sidebar-category-item, .sidebar-category-item .sidebar-menu-toggle {
      color: $primary;
    }
  }
  
  & > hr {
    padding-bottom: $spacer;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sidebar-category-item {
  color: $text-secondary-color;
  font-weight: $font-weight-semi-bold;
  display: flex;
  align-items: center;
  padding: $spacer * .5 $spacer * .75;
  border-radius: $border-radius-lg;

  &.outside-menu {
    background: #f7f7f7;
  }
}

.sidebar-category-item-icon {
  font-size: $font-size-lg;
  margin-right: $spacer * .5;
}

.sidebar-section-menu {
  display: none;
  margin-left: $spacer * 1.5;

  &.menu-open {
    display: block;
  }
}

.sidebar-menu li.active > .sidebar-section-menu {
  display: block;
}

.sidebar-category-item + .sidebar-section-menu {
  margin-left: $spacer * 2;
}

.sidebar-section-menu > li {
  margin-top: $spacer * .25;
  &.active {
    > .sidebar-section-menu-item {
      color: $primary !important;

      &.has-submenu {
        color: $primary !important;
      }
    }
  }
}

.sidebar-section-menu-item {
  color: $text-secondary-color !important;
  font-weight: $font-weight-semi-bold;
  display: flex;
  align-items: center;
  padding: $spacer * .5 $spacer * .75;
  cursor: pointer;

  &:not(.has-submenu) {
    font-weight: $font-weight-normal;
  }
}

.sidebar-category-item, .sidebar-section-menu-item {
  &:hover, &:focus {
    text-decoration: none;
  }
}

li:not(.active) {
  > .sidebar-category-item, > .sidebar-section-menu-item {
    &:hover, &:focus {
      color: $primary !important;
      text-decoration: none;
    }
  }
}
