@font-face {
  font-family: 'hevo-docs';
  src: url('/assets/fonts/hevo-docs.eot');
  src: url('/assets/fonts/hevo-docs.eot?e4ru9y#iefix') format('embedded-opentype'),
  url('/assets/fonts/hevo-docs.ttf?e4ru9y') format('truetype'),
  url('/assets/fonts/hevo-docs.woff?e4ru9y') format('woff'),
  url('/assets/fonts/hevo-docs.svg?e4ru9y#hevo-docs') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="hevo-"], [class*=" hevo-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hevo-docs' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hevo-early-access:before {
  content: "\e904";
}
.hevo-dbt:before {
  content: "\e903";
}
.hevo-billing:before {
  content: "\e905";
}
.hevo-feedback-filled:before {
  content: "\e938";
}
.hevo-sort:before {
  content: "\e937";
}
.hevo-skip:before {
  content: "\e91b";
}
.hevo-graph:before {
  content: "\e93c";
}
.hevo-dropdown-arrow:before {
  content: "\e930";
}
.hevo-edit:before {
  content: "\e931";
}
.hevo-info:before {
  content: "\e932";
}
.hevo-load-status:before {
  content: "\e933";
}
.hevo-pipeline-connection:before {
  content: "\e934";
}
.hevo-up-arrow:before {
  content: "\e935";
}
.hevo-using:before {
  content: "\e936";
}
.hevo-gift:before {
  content: "\e92f";
}
.hevo-share:before {
  content: "\e92e";
}
.hevo-api:before {
  content: "\e92c";
}
.hevo-globe:before {
  content: "\e92d";
}
.hevo-troubleshoot:before {
  content: "\e92b";
}
.hevo-activate:before {
  content: "\e929";
}
.hevo-target:before {
  content: "\e93b";
}
.hevo-plus:before {
  content: "\e925";
}
.hevo-light:before {
  content: "\e926";
}
.hevo-minus:before {
  content: "\e927";
}
.hevo-warning:before {
  content: "\e928";
}
.hevo-settings:before {
  content: "\e924";
}
.hevo-basic:before {
  content: "\e923";
}
.hevo-table-of-contents:before {
  content: "\e922";
}
.hevo-workflow:before {
  content: "\e921";
}
.hevo-schema-mapper:before {
  content: "\e91d";
}
.hevo-success:before {
  content: "\e91e";
}
.hevo-transform:before {
  content: "\e91f";
}
.hevo-preferances:before {
  content: "\e920";
}
.hevo-home:before {
  content: "\e91c";
}
.hevo-about:before {
  content: "\e93a";
}
.hevo-glossary:before {
  content: "\e91a";
}
.hevo-question-mark:before {
  content: "\e919";
}
.hevo-chat:before {
  content: "\e917";
}
.hevo-close:before {
  content: "\e912";
}
.hevo-account-management:before {
  content: "\e913";
}
.hevo-search:before {
  content: "\e914";
}
.hevo-release-notes:before {
  content: "\e915";
}
.hevo-platform:before {
  content: "\e916";
}
.hevo-new-window:before {
  content: "\e900";
}
.hevo-copy:before {
  content: "\e901";
}
.hevo-permalink:before {
  content: "\e902";
}
.hevo-thumbs-down:before {
  content: "\e906";
}
.hevo-thumbs-up:before {
  content: "\e908";
}
.hevo-facebook:before {
  content: "\e939";
}
.hevo-instagram:before {
  content: "\e92a";
}
.hevo-linkedin:before {
  content: "\e918";
}
.hevo-twitter:before {
  content: "\e911";
}
.hevo-youtube:before {
  content: "\e909";
}
.hevo-right-arrow:before {
  content: "\e90a";
}
.hevo-database:before {
  content: "\e90b";
}
.hevo-destinations:before {
  content: "\e90c";
}
.hevo-models:before {
  content: "\e90d";
}
.hevo-pipeline:before {
  content: "\e90e";
}
.hevo-table:before {
  content: "\e90f";
}
.hevo-team:before {
  content: "\e910";
}
