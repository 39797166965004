.schema-diagram-container {
  width: 100%;
  height: 480px;
  margin: $spacer 0;
  position: relative;
}

.schema-diagram {
  width: 100%;
  height: 480px; 
}
