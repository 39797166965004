.navbar--main {
  padding: $spacer;
  z-index: 1001;
  @include media-breakpoint-up(xl) { 
    padding-left: $spacer * 2.8125;
    padding-right: $spacer * 2.8125;
  }
  &.navbar-shadow {
    border-bottom: solid 1px $border-color;
    box-shadow: $shadow-appbar;
  }
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-brand .badge-pill {
    @include badge-style();

    margin-left: $spacer * .25;
    @include media-breakpoint-up(sm) {
      margin-left: $spacer;
    }
  }
  .site-logo {
    max-height: 40px;
    width: auto;
  }

  .search-toggler {
    padding: 0;
    border: 0;
    font-size: 24px;
  }
}

.navbar-search {
  display: none;
  background-color: $white;
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  padding: 0 $spacer;
  box-shadow: $shadow-search-bar;
  border: 1px solid $border-color;
  border-radius: 0;

  .form-inline {
    position: relative;
  }

  @include media-breakpoint-up(md) {
    position: static;   
    margin-left: $spacer * 1.5;
    flex: 1;
    max-width: 100%;
    box-shadow: none;
    display: block !important;
    border-radius: $border-radius-search;
  }

  @include media-breakpoint-up(lg) {
    margin-left: $spacer * 3.875;
    margin-right: auto;
    max-width: 490px;
  }

  &.hasvalue {
    box-shadow: $shadow-search-bar;
  }

  
  input {
    border: 0;
    padding: $spacer * .75;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    color: $input-color;
    font-size: $font-size-sm;

    @include hover-focus {
      box-shadow: none;
      outline: none;
      color: $input-color;
    }

  }
  .search-icon {
    font-size: $font-size-lg;
    color: #5F697E;
  }
  .clear-search {
    vertical-align: top;
    cursor: pointer;
    padding: 0;
    border: 0;
    background-color: transparent;
    line-height: 1;
    font-size: $font-size-base;
    color: #5F697E;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  font-weight: $font-weight-medium;
  font-size: $font-size-sm;
  @include hover-focus {
    text-decoration: none;
  }

  .hevo-docs-icon {
    margin-right: $spacer / 2;
    font-size: $font-size-base;
  }
}

.navbar .container-fluid {
  > .navbar-collapse {
    margin-right: 0;
  }
}

.api-docs-nav-item {
  margin-right: $spacer * 2;
}
