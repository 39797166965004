h2 {
  font-weight: $font-weight-semi-bold;
}

.text-white-60 {
  color: rgba(#ffffff, .6) !important;
}

.lead {
  line-height: $lead-line-height;
  letter-spacing: $lead-letter-spacing;
}

.lead-lg {
  line-height: $lead-lg-line-height;
  font-size: $lead-lg-font-size;
  letter-spacing: normal;
}

p:last-child {
  margin-bottom: 0;
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}
