html {
  font-size: 14px;
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-family: $font-family-base;
  color: $text-color;
}

.sticky-wrapper {
  position: relative;
  &.is-sticky {
    z-index: $zindex-sticky;
  }
}

.included-tabs-content {
  display: none !important;
}


iframe[name='google_conversion_frame'] {
  height: 0 !important;
  width: 0 !important;
  line-height: 0 !important;
  font-size: 0 !important;
  margin-top: -13px;
  float: left;
}