$tag-background-map: (
  "in-queue": $primary,
  "released": #26AF6B,
  "in-progress": map-get($yellow-palette, 300)
);


.upcoming-feature-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: $spacer * 2 0;
  border-bottom: solid 1px $border-color;

  .upcoming-feature-logo {
    $logo-size: $spacer * 3.5; 

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-width: $logo-size;
    height: $logo-size;
    border-radius: 50%;
  }

  .upcoming-feature-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: $spacer * 1.25;
  }

  .feature-title {
    margin-right: $spacer * 0.5;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.5;
  }

  .feature-tag {
    @include badge-sm-style();
  }

  @each $tag, $background in $tag-background-map {
    .feature-tag__#{$tag} {
      background-color: $background;
    }
  }
}
