.home-container {
  .page-header-container {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 4;
    .page-title {
      text-align: center;
      font-size: $font-size-base * 2.25;
      color: $text-strong-color;
      font-weight: $font-weight-bold;
      margin-bottom: $spacer * .5;
    }

    .page-header-desc {
      text-align: center;
      line-height: 1.6;
      color: $text-secondary-color;
      font-size: $font-size-base;
      margin: auto;
      max-width: 500px;
    }
  }
}

.home-category-headline {
  padding: $spacer * 1.25 $spacer * 2.5;
  margin: 0 (-$spacer) ($spacer * 2.5);
  background-color: $doc-page-title-bg-color;
  font-size: $font-size-lg;
  font-weight: bold;
  color: $text-strong-color;
  align-items: center;
  display: flex;

  @include media-breakpoint-up(lg) {
    margin: 0 (-$spacer * 2.5) $spacer * 2.5;
  }

  .hevo-docs-icon {
    font-size: $font-size-lg;
    &::before {
      color: #1282d4;
    }
  }

  a {
    color: $text-strong-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.home-category-description {
  overflow: hidden;
  margin-bottom: $spacer * 3.75;
  .row {
    margin: $spacer * -1;
  }
  .home-description-card {
    padding-bottom: $spacer * 3;
    padding-top: $spacer * 1.5;
    border-bottom: 1px solid $border-color;
    border-left: 1px solid $border-color;
  }
}

.home-description-box {
  background-color: #fff;
  transition: $transition-base;
  padding-left: $spacer * 0.5;
  .text-category {
    font-size: $font-size-base;
  }
}

.home-section-help {
  .home-description-card {
    border: none;
  }
}

$icon-gradient-map: (
  "success": linear-gradient(to bottom, #9b42da, #51509c),
  "database": linear-gradient(to bottom, #2dc0e1, #1482c0),
  "schema-mapper": linear-gradient(to bottom, #3c43d0, #46a7e5),
  "transform": linear-gradient(to bottom, #3c42d0, #4a46e5),
  "destinations": linear-gradient(to bottom, #3c43d0, #46a7e5),
  "basic": linear-gradient(to bottom, #29bbb5, #24d1c4),
  "model": linear-gradient(to bottom, #4d24a3, #637fe2),
  "workflow": linear-gradient(to bottom, #bd3cd0, #a020e5),
  "activate": linear-gradient(#fd8412),
  "target": linear-gradient(225.52deg, #bd3cd0 0%, #a020e5 95.94%),
  "pipeline-connection": linear-gradient(235deg, #9b42da 100%, #51509c 0%),
  "load-status":  linear-gradient(225deg, #2dc0e1 100%, #1482c0),
  "question-mark": linear-gradient(225deg, #bd3cd0, #a020e5 4%),
  "dbt": linear-gradient(#3C43D0, #46A7E5),
  "settings": linear-gradient(#2DC0E1, #1482C0),
  "billing": linear-gradient(#42DAC3, #50799C),
  "account-management": linear-gradient(#9B42DA, #51509C),
);

.home-img-box {
  height: $spacer * 3;
  width: $spacer * 3;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: solid 1px $border-color;
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5FA 100%);

  i {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: $font-size-base * 1.5;
    background-color: $primary;
  }

  @each $icon, $gradient in $icon-gradient-map {
    i.hevo-#{$icon} {
      background-image: $gradient;
    }
  }
}

.home-description-box-name {
  font-size: $font-size-base * 1.125;
  font-weight: bold;
  margin-bottom: inherit;
  color: $text-strong-color;
  a, a:hover, a:focus {
    color: $text-strong-color;
  }
}

ul.home-list-items {
  list-style: none;
  margin-left: $spacer;
  li + li {
    margin-top: $spacer * 0.625;
  }
  a {
    font-weight: $font-weight-medium;
  }
}

ul.home-list-items li::before {
  content: " ";
  width: 6px;
  height: 6px;
  border-radius: $border-radius;
  background-color: $primary;
  display: inline-block;
  margin-left: $spacer * .5;
  margin-right: $spacer * 0.5;
}

.home-description-box-text {
  margin-top: $spacer * 1.25;
  line-height: 1.57;
  font-size: $font-size-sm;
  .list-items {
    margin-top: $spacer * 1.25;
    margin-bottom: 0;
  }
}

.description-read-more {
  bottom: 10px;
  position: absolute;
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
}

.home-share-feedback-text {
  font-size: $font-size-sm;
  position: relative;
  z-index: 9;
  span {
    font-weight: $font-weight-semi-bold;
    color: $primary;
  }
}

@include copy-email-styling;

.entity-quick-links {
  margin-top: $spacer * 1.25;

  a {
    font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;
  } 

  .link-divider {
    margin: 0 $spacer * 1.25;
    color: $border-color;
  }
}

.doc-type-tab-trigger-container {
  display: flex;
  align-items: center;
  background-color: $white;
  border-bottom: solid 1px $border-color;
  button {
    background: none;
    border: none;
    &:focus {
      outline: none;
      text-decoration: none;
    }
  }
  a, button {
    color: $text-secondary-color;
    font-size: $font-size-sm;
    padding: $spacer * .5 $spacer * .75;
    border-bottom: solid 2px transparent;
    text-decoration: none;
    font-weight: $font-weight-normal;
    &.active {
      font-weight: $font-weight-semi-bold;
      color: $text-strong-color;
      border-bottom-color: $primary;
    }
  }
}

.home-doc-type-tabs {
  padding-top: $spacer * .125;
  margin-left: -$spacer;
  margin-right: -$spacer;
  padding-left: $spacer;
  padding-right: $spacer;
  border-top: solid 1px $border-color;
  @include media-breakpoint-up(lg) {
    margin-left: -$spacer * 2.5;
    margin-right: -$spacer * 2.5;
    padding-left: $spacer * 2.5;
    padding-right: $spacer * 2.5;
  }
}
