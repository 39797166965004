@import 'variables';

.upcoming-features-filters {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: $spacer * 2 0;
  border-bottom: 1px solid $border-color;

  .select-dropdown-container {
    width: 18.75rem;
    position: relative;

    .options-container {
      background: $filter-dropdown-bg;
      max-height: 0;
      width: 100%;
      opacity: 0;
      border-radius: $border-radius-sm;
      overflow: hidden;
      position: absolute;
      order: 1;
      z-index: 10;
      top: $spacer * 3;
      box-shadow: $shadow-dropdown;
      transition: all 0.2s ease-in;

      &.active {
        max-height: 20rem;
        opacity: 1;
        overflow-y: scroll;
        outline: 1px solid $border-color;
      }
    }

    .option {
      padding: $spacer * .5 $spacer * .75;
      cursor: pointer;
      font-weight: $lead-font-weight;
      color: $filter-text-color;

      &:hover {
        background: $filter-dropdown-bg-hover;
      }

      &.selected {
        background: $filter-dropdown-bg-hover;
      }

      label {
        cursor: pointer;
        margin: 0
      }
    }
  }

  .select-input-container {
    background: $filter-dropdown-bg;
    border-radius: $border-radius-sm;
    position: relative;
    order: 0;
    padding: $spacer * .625 $spacer * .75;
    cursor: pointer;
    font-weight: $lead-font-weight;
    line-height: 26px;
    transition: all 0.2s;
    color: $filter-text-color;

    &:hover {
      background: $filter-dropdown-bg-hover;
    }

    i.hevo-sort {
      margin-right: $spacer * .5;
    }

    i.hevo-dropdown-arrow {
      transition: transform 0.2s ease-in;
    }
  }

  .select-dropdown-container .options-container.active + .select-input-container i.hevo-dropdown-arrow {
    transform: rotate(180deg);
  }
}