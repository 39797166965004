@mixin node-image-sizer($thumb-size, $spacing) {
  $container-size: $thumb-size + 2 * $spacing;
  width: $container-size;
  height: $container-size;
  img {
    max-width: $thumb-size;
    max-height: $thumb-size;
  }
}

.node-image {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  background-color: rgba(101, 168, 255, 0.1)
}

.node-image-size-xl {
  @include node-image-sizer(60px, 20px);
}

.node-image-rounded-borders {
  border-radius: 50% !important;
  overflow: hidden;
}