// This file contains styling related to lists
// both ordered and unordered


// Remove padding-left for the lists
.doc-section {
  & ol.new-design-ol,
    ul.new-design-ul {
      padding-left: 0;
    }
}


// For Ordered list
ol.new-design-ol {
  list-style: none;
  counter-reset: awesome-counter;

  & > li {
    counter-increment: awesome-counter;
    position: relative;
    @include ol-marker-position();

    &::before {
      content: counter(awesome-counter);
      position: absolute;
      @include ol-marker-sizing();
      display: inline-block;
      border-radius: 50%;
      background: $border-color;
      font-weight: $font-weight-bold;
      text-align: center;
    }
  }
}

ul.new-design-ul {
  & > li {
    list-style: none;
    position: relative;
    @include ul-marker-position();
    &::before {
      content: "";
      position: absolute;
      @include ul-marker-sizing();
      display: inline-block;
      border: 1px solid $ul-list-border-color;
      border-radius: 50%;
    }
  }
}
