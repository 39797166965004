.breadcrumb-nav {
  margin-bottom: $spacer * .75;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: $spacer * 1.5;
  font-size: $font-size-sm;
}

.breadcrumb-item {
  > a {
    color: $text-secondary-color !important;
  }

  & + .breadcrumb-item::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f105";
    width: 16px;
    text-align: center;
    padding-right: 0;
    margin-right: 8px;
  }
}
