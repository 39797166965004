// This file contains styling pertaining to
// the revision history section

.revision-history-table {
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }
}