@mixin badge-style() {
  padding: $spacer * .25 $spacer * .75;
  font-size: $font-size-sm;
  height: 1.875rem;
  display: inline-flex;
  align-items: center;
  background-color: rgba($primary, .1);
  color: $primary;
}

@mixin badge-sm-style($color: $primary) {
  padding: $spacer * .125 $spacer * .5;
  font-size: $font-size-xxs;
  height: 1.25rem;
  border-radius: $border-radius-xl;
  display: inline-flex;
  align-items: center;
  background-color: $color;
  color: $white;
}


/**
 * Sizing the font and padding depending on where the list is being used
 *
 * $dashboard - if showing in dashboard
 */
@mixin ol-marker-sizing($dashboard: false) {
  font-size: $font-size-base * .625;
  $size: 16px;

  @include media-breakpoint-up(lg) {
    height: 1.25rem;
    width: 1.25rem;
    line-height: 1.25rem;
  }

  @if $dashboard {
    $size: 16px;
  }

  height: $size;
  width: $size;
  line-height: $size;
}


/**
 * Sizing the padding depending on where the list is being used
 *
 * $dashboard - if showing in dashboard
 */
 @mixin ul-marker-sizing($dashboard: false) {
  $size: 10px;

  @if $dashboard {
    $size: 6px !important;
  }

  height: $size;
  width: $size;
}

@mixin ul-marker-position($dashboard: false) {
  $gutter-left: $spacer * 1.5;
  $marker-top: $spacer * .5;

  @if $dashboard {
    $gutter-left: $spacer * 1.16;
    $marker-top: $spacer * .5;
  }

  padding-left: $gutter-left;

  &::before {
    left: 0;
    top: $marker-top;
  }
}

@mixin ol-marker-position($dashboard: false) {
  $gutter-left: $spacer * 1.75;
  $marker-top: .125rem;

  padding-left: $gutter-left;

  &::before {
    left: 0;
    top: $marker-top;
  }
}

@mixin collapsible-content() {
  // Dropdown toggle to show/hide collapsible-content
  span.collapsible-toggle {
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: $transition-base;
  }
}

/**
 * styling of the scroll-to-top button
 */
 @mixin scroll-top-styling($dashboard: false) {
  $thumb-size: $spacer * 1.25;
  $padding: $spacer * .5;
  $gutter-x: 80px;
  $gutter-y: $spacer * 1.5;

  @if $dashboard {
    $thumb-size: floating-btn-dash-thumb-size();
    $padding: floating-btn-dash-padding();
    $gutter-x: $spacer * 1.667;
    $gutter-y: $spacer * 5;

    @include floating-btn-dash-color();
  }

  $container-size: $thumb-size + 2 * $padding;
  bottom: $gutter-y;
  right: $gutter-x;
  padding: $padding;
  width: $container-size;
  height: $container-size;
  
  @include floating-btn-styling();
  
  .hevo-docs-icon {
    font-size: $thumb-size;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@mixin copy-email-styling {
  .email-copy {
    display: inline-flex;
    align-items: center;
    color: $link-color;
    background-color: transparent;
    border: none;
    i {
      margin-left: $spacer * .25;
    }
  }
}

@mixin floating-btn-styling {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-md;
  cursor: pointer;
  z-index: 1050;
  transition: all 2ms ease;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

@mixin floating-btn-dash-color {
  color: $floating-btn-color;
  background-color: $floating-btn-bg-color;

  &:hover, &:focus {
    background-color: $accent-dark;
    box-shadow: $shadow-elevated-accent;
  }
}
