@import "variables";

.infographics-section-link {
  font-family: inherit !important;
  font-weight: $font-weight-semi-bold;
  cursor: pointer;
}

.infographics-text {
  font-family: inherit !important;
}

.infographics-container {
  display: flex;
  align-self: flex-start;
  transform: scale(1);
  margin-left: auto;

  @media screen and (max-width: 862px) {
    margin-top: -170px;
  }
}

.etl-svg-container {
  @media screen and (max-width: 862px) {
    margin-top: -120px !important;
    margin-bottom: -140px !important;
  }
}

.elt-svg-container {
  @media screen and (max-width: 862px) {
    margin-top: -100px;
    margin-bottom: -110px;
  }
}

.text-bold {
  font-weight: $font-weight-bold !important;
}