.banner-container {
  background-color: $primary-bg-faded-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacer * 1.5 $spacer * 1.25;
  margin-top: $spacer*2;
  border-radius: $border-radius-xxl;

  & > .banner-text {
    flex: 1;
    font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;
    line-height: 1.4;
    text-align: left;
    margin-right: $spacer;
    max-width: 600px;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-base * 1.1;
    }
  }
}
