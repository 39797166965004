.main-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 70px;
  @include media-breakpoint-up(sm) {
    padding-top: 80px;
  }
}

.doc-wrap {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
}


$doc-left-md-width: 328px;
$doc-left-sm-width: 40px;

.doc-left {
  width: $doc-left-md-width;
  max-width: 100%;
  height: calc(100vh - 70px);
  background-color: $white;
  box-shadow: $shadow-tab;
  position: fixed;
  top: 70px;
  bottom: 0;
  z-index: 10;
  border-right: 1px solid $border-color;
  transform: translateX(-100%);
  transition: $transition-base;

  &:not(.show) {
    display: block;
  }

  .sidebar-wrapper {
    max-height: calc(100vh - 80px);
    overflow: auto;
  }

  @include media-breakpoint-down(lg) {
    &.show {
      transform: translateX(0);
    }
  }

  @include media-breakpoint-up(lg) {
    height: auto;
    max-height: calc(100vh - 80px);
    transform: translateX(0);
    box-shadow: none;
    position: sticky;
    top: 80px;
    width: $doc-left-md-width;
    margin-bottom: -$spacer * 1.25;

    &:not(.show) {
      display: block;
    }

    &.show {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

  }

}

.doc-right {
  padding: $spacer;
  flex: 1;
  min-width: 0;
  @include media-breakpoint-up(lg) {
    padding: $spacer * 1.75 $spacer * 2.5 $spacer * 1.25;
  }
}

.layout-fixed {
  .navbar--main {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #fff;
  }
}

.scroll-top-btn {
  @include scroll-top-styling();

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.highlighted-search-term {
  background-color: yellow;
}
