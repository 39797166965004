.not-found-error {
  margin-bottom: $spacer * 1.875;

  &-img {
    margin-top: $spacer * .125;
    margin-bottom: $spacer;
    width: 120px;
  }

  &-title {
    line-height: $spacer * 3.125;
    font-weight: $font-weight-bold;
    font-size: $h1-font-size;
    color: $text-strong-color;
  }

  &-subtitle {
    max-width: 864px;
    margin: auto;
    font-weight: $lead-font-weight;
  }

  @include media-breakpoint-down (md) {
    margin-top: $spacer * 3;

    &-subtitle {
      width: auto !important;
      margin: 0 $spacer * 2;
    }

    &-title {
      line-height: $spacer * 3.125;
    }

    &-img {
      margin-top: $spacer * 4;
    }
  }
}

.not-found-error-search-wrapper {
  margin-bottom: $spacer * 20;

  & .navbar-search {
    position: relative;
    display: block;
    top: 0;
    width: 648px;
    margin: auto;
    box-shadow: $shadow-search-bar;

    & #hits .list-group {
      max-height: 275px;
    }
  }

  @media screen and (min-height: 900px){
    margin-bottom: $spacer * 28;

    & .navbar-search #hits .list-group {
      max-height: 400px;
    }
  }

  @include media-breakpoint-down(md) {
    & .navbar-search {
      position: relative;
      display: block;
      top: 0;
      width: min(448px, 100%);
      margin: auto;
      box-shadow: $shadow-search-bar;

      & #hits .list-group {
        max-height: 275px;
      }
    }
  }
}
