$feedback__container-padding: $spacer * 2;
$feedback__container-gutter-x: $spacer * 1.25;
$feedback__container-gutter-y: 172px;

$feedback__close-font-size: $font-size-lg;
$feedback__close-position: $spacer * .75;

$feedback__header-font-size: $h1-font-size;
$feedback__header-line-height: normal;
$feedback__header-bottom-spacer: $spacer * 1.5;

$feedback__title-left-spacer: $spacer * .75;
$feedback__title-font-weight: normal;

$feedback__submit-btn-color: $white;
$feedback__submit-btn-bg-color: $primary;
$feedback__submit-btn-top-spacer: $spacer * 2;


// Feedback Anchor
$feedback-anchor__size: $spacer * 3;
$feedback-anchor__gutter-x: $spacer * 1.25;
$feedback-anchor__gutter-y: $spacer * 6.5;

$feedback-anchor__icon-font-size: $font-size-base * 2.25;
