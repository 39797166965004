$primary:                     rgb(91, 44, 201);
$primary-bg-faded-color:      var(--primary-bg-faded-color);
$primary-bg-faded-1-color:    var(--primary-bg-faded-1-color);

$text-strong-color:           var(--text-strong-color);
$text-color:                  var(--text-color);
$text-secondary-color:        var(--text-secondary-color);

$link-color:                  var(--link-color);
$link-hover-color:            var(--link-hover-color);

$border-color:                var(--border-color);

$body-color:                  $text-color;
$input-color:                 $text-color;

$accent-dark:                 map-get($blue-palette, 800);

$white:                       map-get($white-palette, 0);
$off-white:                   map-get($white-palette, 100);
$white-alter:                 map-get($white-palette, 200);


$navbar-light-color:                $link-color;
$navbar-light-hover-color:          $link-hover-color;
$navbar-light-active-color:         $link-hover-color;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%2332325d' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

$filter-dropdown-bg:                #f7f7f7;
$filter-dropdown-bg-hover:          darken($filter-dropdown-bg, 4%);
$filter-text-color:                 #0E2843;

$font-family-inter:           'Inter', sans-serif;
$font-family-base:            $font-family-inter;

$font-weight-medium:          500;
$font-weight-semi-bold:       600;

$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-md:                $font-size-base * .9375;
$font-size-sm:                $font-size-base * .875;
$font-size-xs:                $font-size-base * .8125;
$font-size-xxs:               $font-size-base * .75;
$line-height-base:            1.6;

$h1-font-size:                $font-size-base * 1.625;
$h2-font-size:                $font-size-base * 1.25;
$h3-font-size:                $font-size-base * 1.125;
$h4-font-size:                $font-size-base;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.75;

$lead-font-weight:            400;
$lead-line-height:            1.5;
$lead-letter-spacing:         1px;
$lead-lg-line-height:         1.83;
$lead-lg-font-size:           $font-size-base * 1.25;


// Components
//
// Define common padding and border radius sizes and more.
$border-radius:               .125rem;
$border-radius-sm:            .25rem;
$border-radius-md:            .375rem;
$border-radius-lg:            .5rem;
$border-radius-xl:            .625rem;
$border-radius-xxl:           .75rem;

$border-radius-search:         $border-radius-xl;

// SHADOWS

$shadow-appbar:                     0 1px 2px 0 rgba(10, 13, 20, 0.03);
$shadow-tab:                        0 2px 4px 0 rgba(0, 0, 0, 0.17);
$shadow-dropdown:                   0 2px 4px 0 rgba(0, 0, 0, 0.1);
$shadow-elevated:                   0 -6px 16px 0 rgba(0, 0, 0, 0.03), 0 8px 16px 0 rgba(0, 0, 0, 0.03), 0 13px 27px 0 rgba(50, 50, 93, 0.25);
$shadow-search-bar:                 0px 0px 4px rgba(0, 0, 0, 0.15);

$hr-margin-y:                 2.5rem;

$surface-bg-color:            #fff;


// Based on theme variable values
$code-highlighter-color:            var(--code-highlighter-color);
$code-highlighter-bg-color:         var(--code-highlighter-bg-color);

$tr-alternate-bg-color:             var(--tr-alternate-bg-color);
$ul-list-border-color:              var(--ul-list-border-color);

$doc-page-title-bg-color:           var(--doc-page-title-bg-color);

$note-container-color-info:         var(--note-container-color-info);
$note-container-color-warn:         var(--note-container-color-warn);
$note-container-color-error:        var(--note-container-color-error);


$button-bg:                         var(--button-bg);
$button-bg-hover:                   var(--button-bg-hover);
