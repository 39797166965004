.footer {
  border-top: 1px solid $border-color;
  padding: $spacer * 2.5 $spacer;
  background-color: #fff;
  margin: 0 -$spacer;

  @include media-breakpoint-up(lg) {
    margin: 0 -$spacer * 2.5;
    padding-left: $spacer * 2.5;
    padding-right: $spacer * 2.5;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 2.8125;
    padding-right: $spacer * 2.8125;
  }

  .container-fluid {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.footer-nav {
  flex-direction: column;
  align-items: center;
  margin-bottom: $spacer;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin-bottom: $spacer * 1.25;
  }

  .nav-link {
    padding: 0;
    color: $text-secondary-color;
    @include hover-focus {
      color: $primary;
    }
  }

  .nav-link + .nav-link {
    margin-top: $spacer;

    @include media-breakpoint-up(lg) {
      margin-left: $spacer * 1.25;
      margin-top: 0;
    }
  }
}

.footer-copyright {
  color: $text-secondary-color;
  font-size: $font-size-sm;
}

.footer-social-icon {
  font-size: $font-size-lg;
  color: $text-secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition-base;
  @include hover-focus {
    color: $primary;
    text-decoration: none;
  }
}

.footer-social-icon + .footer-social-icon {
  margin-left: $spacer * .5;
}

.footer-social-block {
  margin-bottom: $spacer;
  /*
    Increased the margin-right so that
    scroll-to-top btn doesn't overlay the
    social-block-container
  */
  @include media-breakpoint-up(lg) {
    margin-right: $spacer * 7;
    margin-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    margin-right: $spacer * 5;
  }
}
